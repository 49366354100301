import { useState } from "react";
import List from "./list";
import About from "./about";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/analytics';
import SeasonalList from "./seasonalList";

import emailjs from 'emailjs-com';



firebase.initializeApp({ 
    apiKey: "AIzaSyDWGcoeEP5DwJdpI4A7Mt84u1r5y1Am_X8",
    authDomain: "giveback-e9fcf.firebaseapp.com",
    projectId: "giveback-e9fcf",
    storageBucket: "giveback-e9fcf.appspot.com",
    messagingSenderId: "896595308811",
    appId: "1:896595308811:web:ab0706adb5707e7eb2320e",
    measurementId: "G-8TTFRSQKLZ"
})

const auth = firebase.auth();
const firestore = firebase.firestore()

function App() {

  const [adminForm, setAdminForm] = useState(false);
  const [formData, setFormData] = useState("")
  const [user, setUser] = useState(false)


  const [isList, setIslist] = useState(true);


        const [error, setError] = useState(false)
        const [sent, setSent] = useState(false)
  const sendEmail = (e) =>{
    e.preventDefault();
    console.log(e.target)
    emailjs.sendForm('service_32t3byy', 'template_arr9obc', e.target, 'nKpLTNT41Mz6mELOL')
    .then((result) => {
        console.log(result.text);
        setError(false)
        setSent(true)
    }, (error) => {
        console.log(error.text);
        setError(true)
        setSent(false)
    });

    e.target.reset()
} 

  const handleChange = (e) =>{
    const {name,value} = e.target;
    setFormData(formData =>({
        ...formData,
        [name]:value
    }))
    }

    const handleSubmit = (e) =>{
      e.preventDefault()
      if(formData.password === "admin"){
        console.log("True")
      setUser(true)
      }
      else{
        console.log("False")
        setUser(false)
      }
      setAdminForm(false)
      setFormData("")
    }

    const handleTab = (e) =>{
      if(e.target.name === "abt"){
        setIslist(false)
      }
      if(e.target.name === "list"){
        setIslist(true)
      }
    }
  
  return (
    <div className="w-full min-h-screen  bg-gray-100"> 

        <div className="bg-gradient-to-r from-emerald-400 to-green-700 p-5">
          <button onClick={()=>setAdminForm(!adminForm)} className="fixed right-1 text-sm">Admin</button>
          {adminForm ? 
          <div className="right-20 fixed p-2 bg-gray-100">
            <form>
              <input placeholder="Password" type="text" name="password" value={formData.password} onChange={handleChange}></input>
              <button className="p-1 mx-1 border bg-gray-200" onClick={handleSubmit}>Submit</button>
            </form>
          </div>
          :
          null}
          <h1 className="text-sky-200 text-5xl text-center border-b-2 w-4/5 sm:w-2/5 md:w-1/5 mx-auto  p-6  first-letter
          border-2 rounded-full border-green-400 font-semibold">
            Project Pace {user ? "ADMIN" : null}
            </h1>

        <div className="w-1/2 mx-auto text-center pt-4">
        <button name="list" onClick={handleTab} className="w-20 border font-semibold text-gray-300 border-green-400 px-4 rounded-lg m-2 hover:bg-emerald-400 duration-150">List</button>
        <button name="abt" onClick={handleTab} className="w-20 border font-semibold text-gray-300 border-green-400 px-4 rounded-lg m-2 hover:bg-emerald-400 duration-150">About</button>
        </div>

      

        <p className="w-2/3 mx-auto text-center pb-2 text-gray-200 text-lg">
            You can assist in Project PACE by donating these items below to your local PACE center
            or you can use the Amazon wishlist link below to donate without
            having to deliver the items yourself.
            Below is a list of PACE locations where you can drop off the items.
          </p>

        </div>

    
    <div className="bg-gray-200 w-11/12 md:w-8/12 mx-auto shadow-lg">
        {isList ? 
        <>
        
        <h1 className="text-center text-3xl text-gray-500 underline underline-offset-8 mb-8 p-4">
          <a href="https://www.amazon.com/hz/wishlist/ls/38AHMTNHSUE4S?ref_=wl_share ">Amazon Wish List</a></h1>
      

        <div className="md:grid md:grid-cols-2  w-3/4 mx-auto">
        <List firestore={firestore} user={user}/>
        <SeasonalList firestore={firestore} user={user}/>
        </div>

        <div className="text-center md:w-5/12 mx-auto p-2">

          <span className="text-left p-4">
            <h1 className="mt-6 text-2xl">Items can be donated at the front desk at these PACE locations:</h1>
            <ul className="my-4 text-md p-2  ">
              <li className="my-6"><a  className="underline">Southfield Center</a> <br/> 24463 West Ten Mile Road, Southfield, MI 48033 </li>
              <li className="my-6"><a  className="underline">Thome Rivertown Center</a> <br/> 250 McDougall Ave., Detroit, MI 48207 </li>
              <li className="my-6"><a  className="underline">Eastpointe Center</a> <br/> 17401 East Ten Mile Road, Eastpointe, MI 48021 </li>
              <li className="my-6"><a  className="underline">Dearborn Center</a> <br/> 15401 North Commerce Dr., Dearborn, MI 48120 </li>
              <li className="my-6"><a  className="underline">Detroit Center</a> <br/> 17330 Greydale Ave., Detroit, MI 48219 </li>
              <li className="my-6"><a  className="underline">Sterling Heights Center</a> <br/> 35501 Mound Rd., Sterling Heights, MI 48310 </li>
              <li className="my-6"><a  className="underline">Pontiac Center</a> <br/> 823 Golf Dr., Pontiac, MI 48341 </li>


            </ul>
          </span>

          
        </div>

        
        </>
        :
        <About/>}


  </div>

 
    </div>
  );
}

export default App;
