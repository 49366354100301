

const About = () =>{
    return(
        <div className="mx-auto border  w-full md:w-2/3 text-center p-5">
            <h1 className="text-center text-2xl mb-3">About Project Pace</h1>
            <p className="w-5/6 mx-auto text-md mb-2">
                Project PACE was created by University of Michigan-Flint Physician Assistant students:
                Nicholas Diroff and Nikola Ilic. As we began our clinical year being immersed in multiple
                patient populations we noticed that one of the populations that need extra help when it
                comes to patient advocacy, heath care, and resources was the geriatric population. As
                future health care providers, we wanted to offer more to our elderly community—thus
                Project PACE was born.
                <br/>
                We purposed the idea to reach out to geriatric nursing homes, specifically those that
                take care of geriatric residents with limited resources, supplies, and efforts of obtaining
                daily necessities.
                <br/>
                Nicholas, being a prior Medical Assistant at PACE senior living, reached out to his
                coworkers and explained our idea of offering a service that allows community members
                to directly volunteer and donate to geriatric residents in need of resources.
                <br/>
                Thus we created a website which acts as a middle man in reaching out to Southeast
                Michigan residents, to provide them with a list of necessities that geriatric residents at
                various PACE senior living locations are in need of.
                </p>

                <div className="">
                <div>
                    <h1 className="text-center text-2xl mb-3">Goals</h1>
                    <p className="w-5/6 mx-auto text-md">
                        Our goal of this project is to create an easily accessible system that ultimately allows
                        neighbors to become aware of some of the needs geriatric residents require and offer a
                        hand in providing support for this community.
                    </p>
                </div>
                <div>
                <h1 className="text-center text-2xl mb-3 mt-4">About Pace Senior Living</h1>
                <p  className="w-5/6 mx-auto text-md">
                PACE Southeast Michigan is a Program of All-Inclusive Care for the Elderly (PACE). PACE
                Southeast Michigan uses Medicare, Medicaid and private funds to cover all geriatric
                medically necessary care and services.
                Community members can learn more about PACE, their goals, values, and opportunities
                to volunteer through their website, which is provided in a link below: <br/>
                <a href="https://www.pacesemi.org/">https://www.pacesemi.org/</a>
                </p>

                </div>

                </div>
        </div>
    )
}


export default About;