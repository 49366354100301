import { useEffect, useState } from 'react';
import {deleteDoc, onSnapshot, serverTimestamp, doc } from "firebase/firestore";




const List = ({firestore,user}) =>{

    

    const [formValue, setFormValue] = useState('');

    const [items, setItems] = useState([]);

    useEffect(()=>{
        const q = firestore.collection("houseHoldItem");
        const snap = onSnapshot(q, (querySnapshot) =>{
            let itemsArr = [];
            querySnapshot.forEach((doc)=>{
                itemsArr.push({...doc.data(), id: doc.id})
            })
            setItems(itemsArr)
        })
        return()=> snap()
    },[])


    

    //const listItem = firestore.collection("houseHoldItem");
    //const [items] = useCollectionData(listItem, {idField: "id"})


    const handleSubmit = async(e) =>{
        e.preventDefault();
        if(user){
            await firestore.collection("houseHoldItem").doc().set({
                text:formValue,
                createdAt: serverTimestamp()
            })
        }
        setFormValue('')
    }


    const handleDelete = async(id) =>{
        await deleteDoc(doc(firestore, "houseHoldItem", id))
    }
return(

    <div className=' mx-auto  p-1'>
        <h1 className='text-center p-2 text-gray-500 text-4xl'>Household Items</h1>

        
        <>
        {user ? <form onSubmit={handleSubmit} className="mb-2">
        <input className='bg-gray-200 border-2 w-10/12'placeholder='New Item'
         value={formValue} onChange={(e)=> setFormValue(e.target.value)}/>
         <button className='p-1 px-2 bg-blue-500'>Add</button>
         </form> : null}
        <ol type="1" className='mx-auto text-center w-full  pl-3 text-xl'>
        {items && items.map(item =>{
            return(              
                <li>{item.text}
                {user ? <button onClick={()=>handleDelete(item.id)} 
                className='bg-red-400 text-white '>x</button> 
                :null}
                </li>
                
                )
        })}
        
        </ol>
        </>
    
    
    </div>
)
}

export default List;